body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* General UI */
#root {
    display: flex;
    flex: 1;
    height: 100vh;
    overflow: hidden;
    min-width: 320px;
    flex-flow: column;
}
.pageContainer {
    display: flex !important;
    flex: 1;
    flex-direction: column;
    padding: 0 !important;
    overflow: scroll;
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2),
                0px 3px 4px 0px rgba(0,0,0,0.14),
                0px 1px 8px 0px rgba(0,0,0,0.12);
}
.fullSizeCenteredContainer {
    display: flex !important;
    flex: auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.MuiCard-root {
    margin: 0 15px 20px;
    padding: 10px 0 15px;
    overflow: visible;
}
.MuiCard-root:first-child {
    margin-top: 20px;
}
.MuiCardActions-root {
    padding: 5px 16px !important;
    justify-content: space-between;
}
.MuiCardActions-root.cardActionCenter {
    justify-content: center;
}
.divider {
    margin: 20px 0;
}
.divider-prebuttons {
    margin: 20px 0 0;
}
.detailsCardTitle {
    text-align: center;
    margin-bottom: 20px !important;
    font-weight: bold !important;
}
.MuiDialog-container .MuiCard-root:not(:first-child) {
    margin-top: 25px;
}
.MuiFormControl-root, .MuiOutlinedInput-root{
    width: 100%;
}
.detailsCardContent input, .detailsCardContent textarea {
    color: #000 !important;
    -webkit-text-fill-color: #000 !important;
}

/* Breadcrumbs */
.breadcrumb-link {
    display: flex;
    color: white;
    text-decoration: none;
    align-items: "center";
}
.breadcrumb-link svg {
    margin: auto 3px auto 0;
    display: block;
    width: 22px;
    height: 22px;
}
.breadcrumb-last-link {
    font-weight: bold;
}


/* LISTS (better perfs) */
.list-item {
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    color: inherit;
    cursor: pointer;
}
.client-list-item:hover {
    background-color: #fafafa;
}
.client-list-item .client-list-item-text {
    padding-right: 38px;
}
.client-list-item .animal-link {
    color: black;
    text-decoration: none;
    margin-right: 6px;
    margin-top: 6px;
    font-size: 0.9rem;
    text-transform: none;
    background: #eee;
    border-radius: 15px;
    padding: 4px 10px;
}
.client-list-item .animal-link:hover,
.client-list-item .animal-link:focus,
.client-list-item .animal-link:active {
    background-color: #d7d7d7;
}

.animal-deceased-list-item,
.client-list-item .animal-link-deceased {
    text-decoration: line-through !important;
}
.animal-deceased-list-item .tombstone-icon,
.client-list-item .animal-link-deceased .tombstone-icon  {
    margin-left: 6px
}


/* EDITOR */
.drawn-report-editor .toolbar-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: white;
    height: 64px;
    border-top: 1px solid #c4c4c4;
    border-radius: 0
}
.drawn-report-editor .toolbar {
    display: flex;
    flex: 1;
    height: 64px;
    max-width: 1168px;
    margin: auto;
}
.drawn-report-editor .toolbar button {
    display: flex;
    flex: 1;
    border: none;
    border-radius: 0;
    border-right: 1px solid #c4c4c4 !important
}
.drawn-report-editor .toolbar button:last-child {
    border: none !important
}
.drawn-report-editor .toolbar button:hover {
    border: none
}

#add-shape-menu .menu {
    left: 0 !important;
    top: auto !important;
    bottom: 64px;
    width: 50%;
    border-radius: 0;
    border-right: 1px solid #c4c4c4;
    border-bottom: 1px dotted #c4c4c4;
    box-shadow: 0px -3px 3px 0px rgb(0 0 0 / 20%);
}
.drawn-report-editor .toolbar .add-shape-button-selected {
    font-weight: bold
}
.drawn-report-editor .toolbar .toolbar-infos {
    display: flex;
    flex: 1;
    align-items: center;
    flex-wrap: wrap;
    font-weight: bold;
    padding-left: 15px
}
.drawn-report-editor .toolbar button.toolbar-right-button {
    flex: none !important;
    padding-left: 20px;
    padding-right: 20px;
}


@media (min-width: 1200px) {
    .drawn-report-editor .toolbar {
        border-left: 1px solid #c4c4c4 !important;
        border-right: 1px solid #c4c4c4 !important;
    }
    #add-shape-menu .menu {
        left: calc((100% - 1168px)/2) !important;
        margin: auto;
        max-width: 584px
    }
}